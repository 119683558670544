import { faExternalLinkAlt, faToolbox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql } from 'gatsby';
import * as PropTypes from 'prop-types';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Img from 'gatsby-image';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import BackgroundImage from 'gatsby-background-image';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import SEO from '../../../components/SEO';
import Layout from '../../../components/Layout';
import LocalizedLink from '../../../components/LocalizedLink';

export default function SAPAdvice({ location, data, pathContext: { locale, pageSlugs } }) {
  const { markdownFileText: { frontmatter } } = data;

  return (
    <Layout location={location} locale={locale} pageSlugs={pageSlugs}>
      <SEO
        title={frontmatter.title}
        description={frontmatter.description}
        language={locale}
      />
      <BackgroundImage
        tag="div"
        className="d-table w-100 h-175-px"
        fluid={data.datArtSAPHeaderImage.childImageSharp.fluid}
      >
        <div className="d-table-cell align-middle text-center">
          <h1
            className="d-inline-block title-font-size text-white text-center bg-darken rounded p-4
            mx-4"
          >
            {frontmatter.mainTitle}
          </h1>
        </div>
      </BackgroundImage>
      {/* <BackgroundImage */}
      {/*  Tag="div" */}
      {/*  className="w-100 min-vh-100 opacity-test" */}
      {/*  fixed={data.datArtSAPBGImage.childImageSharp.fixed} */}
      {/*  preserveStackingContext */}
      {/* > */}
      {/*  <Container className="p-4 opacity-100"> */}
      {/*    <p className="opacity-100"> */}
      {/*      Nos prestations de conseil consistent à missionner au sein de votre équipe */}
      {/*      informatique un ou plusieurs consultants pour vous accompagner dans la transformation
      */}
      {/*      de votre système d’information. Selon le contexte et les objectifs de la mission, */}
      {/*      leur rôle pourra revêtir plusieurs formes comme piloter la mise en œuvre de la */}
      {/*      stratégie SI, coacher et faire monter en compétence vos équipes internes, vous */}
      {/*      accompagner dans la réalisation de vos projets ou vous conseiller dans la mise en */}
      {/*      œuvre de l’évolution de l’infrastructure. */}
      {/*    </p> */}
      {/*  </Container> */}
      {/* </BackgroundImage> */}
      {/* <Container className="p-4 min-vh-100"> */}
      <Container className="p-4">
        <p>{frontmatter.introText}</p>
        <div className="bg-grey w-100 p-4 mx-auto mt-4 rounded special-shadow">
          <h2 className="h4 text-info">{frontmatter.phase1Title}</h2>
          <p className="lead">{frontmatter.phase1Text}</p>
          <hr />
          <h2 className="h4 text-info">{frontmatter.phase2Title}</h2>
          <p className="lead">{frontmatter.phase2Text}</p>
          <hr />
          <h2 className="h4 text-info">{frontmatter.phase3Title}</h2>
          <p className="lead">{frontmatter.phase3Text}</p>
          <hr />
          <h2 className="h4 text-info">{frontmatter.phase4Title}</h2>
          <p className="lead">{frontmatter.phase4Text}</p>
          <hr />
          <h2 className="h4 text-info">{frontmatter.phase5Title}</h2>
          <p className="lead">{frontmatter.phase5Text}</p>
        </div>
        <p className="lead text-center mt-5 font-weight-bold text-primary">
          {frontmatter.contactText}
        </p>
        <p className="text-center">
          <LocalizedLink
            to="/contact/#contact"
            state={{ sourcePage: frontmatter.title }}
          >
            <Button>{frontmatter.contactButtonText}</Button>
          </LocalizedLink>
        </p>
      </Container>
    </Layout>
  );
}

SAPAdvice.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  pathContext: PropTypes.object.isRequired,
};

export const query = graphql`
    fragment pagesHeaderFluidImage on File {
        childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
  
    fragment backgroundFixedImage on File {
        childImageSharp {
            fixed(grayscale: true, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
    }

    query($locale: String!) {
        markdownFileText: markdownRemark(frontmatter: {lang: {eq: $locale}} fields: {fileName:
        {eq: "sap-services-consulting-training"}}) {
            frontmatter {
                title
                description

                mainTitle
                
                introText
                
                phase1Title
                phase1Text
                phase2Title
                phase2Text
                phase3Title
                phase3Text
                phase4Title
                phase4Text
                phase5Title
                phase5Text
                
                contactText
                contactButtonText
            }
        }
        
        datArtSAPHeaderImage: file(relativePath: {regex: "/datArtSAPHeaderImage/"}) {
            ...pagesHeaderFluidImage
        }

        datArtSAPBGImage: file(relativePath: {regex: "/datArtSAPBGImage/"}) {
            ...backgroundFixedImage
        }
    }
`;
